<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t("USER_MANAGEMENT.USER_INVITATIONS.CREATE.ASSIGN.ROLES.EXPANSION_PANEL_HEADLINE") }}
        {{ displayRolesCount }}
      </v-expansion-panel-header>
      <v-divider />
      <v-expansion-panel-content>
        <v-card flat color="transparent" class="pa-0">
          <v-card-title v-if="!readonly" class="py-2 px-0">
            <v-spacer />
            <assign-dialog
              v-model="assignedRoles"
              :all-values="allRoles"
              :headers="headers"
              :headline="
                $t('USER_MANAGEMENT.USER_INVITATIONS.CREATE.ASSIGN.ROLES.ASSIGN_DIALOG.HEADLINE')
              "
              :assigned-values-headline="
                $t(
                  'USER_MANAGEMENT.USER_INVITATIONS.CREATE.ASSIGN.ROLES.ASSIGN_DIALOG.ASSIGNED_VALUES_HEADLINE'
                )
              "
              :unassigned-values-headline="
                $t(
                  'USER_MANAGEMENT.USER_INVITATIONS.CREATE.ASSIGN.ROLES.ASSIGN_DIALOG.UNASSIGNED_VALUES_HEADLINE'
                )
              "
              small
            />
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table :items="assignedRoles" :headers="headers" />
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions } from "vuex";
import AssignDialog from "../partials/AssignDialog";

export default {
  name: "AssignRolesTable",
  components: { AssignDialog },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      assignedRoles: this.value,
      allRoles: [],
      headers: [{ text: "Name", value: "displayName", sortable: true }],
    };
  },

  watch: {
    value: {
      deep: true,
      handler(value) {
        this.assignedRoles = value;
      },
    },
    assignedRoles: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      },
    },
  },

  computed: {
    displayRolesCount() {
      return `(${this.assignedRoles.length.toString()})`;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async getAllRoles() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.roles.listRoles({
          perPage: 1000,
          page: 1,
          sortBy: ["displayName"],
          sortDesc: [false],
        });
        this.allRoles = data.data;
      } catch (err) {
        console.error(err);
        await this.notify({
          type: "error",
          message: "USER_MANAGEMENT.USER_INVITATIONS.CREATE.ASSIGN.ROLES.ERROR_MESSAGE",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.getAllRoles();
  },
};
</script>
