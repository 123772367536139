<template>
  <v-dialog v-model="showDialog" fullscreen persistent>
    <template #activator="{ on }">
      <BaseButton v-on="on" color="primary" :small="small">
        {{ $t("COMPONENTS.ASSIGN_DIALOG.BUTTON_ACTION") }}
      </BaseButton>
    </template>

    <v-card class="pa-0">
      <v-card-text>
        <AssignForm
          v-model="values"
          :allValues="allValues"
          :headers="headers"
          :headline="headline"
          :assignedValuesHeadline="assignedValuesHeadline"
          :unassignedValuesHeadline="unassignedValuesHeadline"
          @close="close"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AssignForm from "./AssignForm";

export default {
  name: "AssignDialog",

  components: {
    AssignForm,
  },

  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },

    allValues: {
      type: Array,
      required: true,
      default: () => [],
    },

    headers: {
      type: Array,
      required: true,
      default: () => [],
    },

    headline: {
      type: String,
      required: true,
      default: null,
    },
    assignedValuesHeadline: {
      type: String,
      required: true,
      default: null,
    },

    unassignedValuesHeadline: {
      type: String,
      required: true,
      default: null,
    },

    icon: {
      type: Boolean,
      required: false,
      default: false,
    },

    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showDialog: false,
      values: this.value,
    };
  },

  watch: {
    value: {
      deep: true,
      handler(value) {
        this.values = value;
      },
    },

    values: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    close() {
      this.showDialog = false;
    },
  },
};
</script>
