<template>
  <v-card color="transparent" flat>
    <v-card-title class="overline">
      {{ headline }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="5">
          <v-card>
            <v-card-title>
              {{ assignedValuesHeadline }}
            </v-card-title>

            <v-card-text class="pa-0">
              <v-data-table
                v-model="selectedAssignedValues"
                :items="assignedValues"
                :headers="headers"
                class="elevation-2"
                show-select
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="2" class="text-center" align-self="center">
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" @click="unassignSelected" :disabled="disableChevronRight">
                <v-icon>far fa-chevron-right</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" @click="unassignAll" :disabled="disableChevronDoubleRight">
                <v-icon>far fa-chevron-double-right</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" @click="assignSelected" :disabled="disableChevronLeft">
                <v-icon>far fa-chevron-left</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" @click="assignAll" :disabled="disableChevronDoubleLeft">
                <v-icon>far fa-chevron-double-left</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="5">
          <v-card>
            <v-card-title>
              {{ unassignedValuesHeadline }}
            </v-card-title>

            <v-card-text class="pa-0">
              <v-data-table
                v-model="selectedUnassignedValues"
                :items="unassignedValues"
                :headers="headers"
                class="elevation-2"
                show-select
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-spacer />

        <v-col cols="12" md="4" lg="2">
          <BaseButton outlined color="error" @click="close" block small>
            {{ $t("COMPONENTS.ASSIGN_FORM.BTN_LABELS.CLOSE") }}
          </BaseButton>
        </v-col>

        <v-col cols="12" md="4" lg="2">
          <BaseButton @click="close" block small>
            {{ $t("COMPONENTS.ASSIGN_FORM.BTN_LABELS.CONFIRM") }}
          </BaseButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "AssignForm",
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },

    allValues: {
      type: Array,
      required: true,
      default: () => [],
    },

    headers: {
      type: Array,
      required: true,
      default: () => [],
    },

    headline: {
      type: String,
      required: true,
      default: null,
    },

    assignedValuesHeadline: {
      type: String,
      required: true,
      default: null,
    },

    unassignedValuesHeadline: {
      type: String,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      showDialog: false,
      assignedValues: this.value,
      selectedAssignedValues: [],
      selectedUnassignedValues: [],
    };
  },

  computed: {
    unassignedValues() {
      return this.allValues.filter((allValue) => {
        return !this.assignedValues.some((assignedValue) => {
          return allValue.id === assignedValue.id;
        });
      });
    },

    disableChevronRight() {
      return this.selectedAssignedValues.length === 0;
    },

    disableChevronDoubleRight() {
      return this.assignedValues.length === 0;
    },
    disableChevronLeft() {
      return this.selectedUnassignedValues.length === 0;
    },

    disableChevronDoubleLeft() {
      return this.unassignedValues.length === 0;
    },
  },

  watch: {
    value: {
      deep: true,
      handler(value) {
        this.assignedValues = value;
      },
    },

    assignedValues: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    assignSelected() {
      this.assignedValues.push(...this.selectedUnassignedValues);
      this.selectedUnassignedValues = [];
    },

    assignAll() {
      this.assignedValues = this.allValues;
    },

    unassignSelected() {
      this.assignedValues = this.assignedValues.filter((assignedValue) => {
        return !this.selectedAssignedValues.some((selectedAssignedValue) => {
          return selectedAssignedValue.id === assignedValue.id;
        });
      });
      this.selectedAssignedValues = [];
    },

    unassignAll() {
      this.assignedValues = [];
    },
  },
};
</script>
